import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'invitationBanner.title',
    defaultMessage:
      'Use Porsche Charging Service with no monthly fee for the next 3 years!',
    description: 'Title of the invitation Banner',
  },
  subTitle1: {
    id: 'invitationBanner.subTitle1',
    defaultMessage:
      'Porsche Charging Service allows you to charge your vehicle on more that 500 000 charging stations across Europe.',
    description: 'First subtitle of the invitation Banner',
  },
  subTitle2: {
    id: 'invitationBanner.subTitle2',
    defaultMessage:
      'Your charging prices are defined by your tariff and are valid on any charging station.',
    description: 'Second subtitle of the invitation Banner',
  },
  subTitle3: {
    id: 'invitationBanner.subTitle3',
    defaultMessage:
      'Charging sessions will be billed monthly and you always have the overview of your charging history and invoices.',
    description: 'Third subtitle of the invitation Banner',
  },
});
