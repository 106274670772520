import { PText, PTextProps } from '@porsche-design-system/components-react';
import React from 'react';
import styles from './ErrorCode.module.scss';

const uuidRegex =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;

type ErrorCodeProps = PTextProps & {
  errorCode: string | null;
  requestId?: string | null;
};

/**
 * Displays a small inline error message containing the request ID and the error code.
 *
 * @param ErrorCodeProps
 * @returns
 */
export const ErrorCode: React.FC<ErrorCodeProps> = ({
  errorCode,
  requestId,
  ...rest
}) => {
  if (!errorCode) {
    return;
  }

  if (!/^[a-zA-Z0-9]{1,6}$/.test(errorCode)) {
    return;
  }

  if (requestId && !uuidRegex.test(requestId)) {
    return;
  }

  return (
    <PText className={styles.errorCode} tag="span" {...rest}>
      &nbsp;{errorCode}
      {requestId && '#' + requestId}
    </PText>
  );
};
