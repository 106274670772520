import {
  BasePricing,
  FeeType,
  RawPlan,
} from 'components/context/plan/planContext.types';
import { vehicleContext } from 'components/context/vehicle/VehicleContextProvider';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { Price, Region } from 'hooks/usePlans';
import { Address } from 'pages/activation/components/AddressPreview';
import { PaymentData } from 'pages/activation/components/PaymentWidget/usePaymentWidget';
import {
  CreditCard,
  CreditCradType,
  PaymentMethod,
} from 'pages/management/components/subscriptionManagement/useSubscriptionManagement';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export type RedesignData = {
  vin: string | null;
  deliveryAddress: Address | null;
  plan: RawPlan | null;
  invoiceAddress: Address | null;
  paymentRef: CreditCard | null;
  isDeliveryAddressSameAsInvoiceAddress: boolean | null;
  marketplace?: string | null;
};

const defaultData: RedesignData = {
  vin: null,
  deliveryAddress: null,
  plan: null,
  invoiceAddress: null,
  paymentRef: null,
  isDeliveryAddressSameAsInvoiceAddress: null,
  marketplace: null,
};

const localStoreageKey = 'invitationRedesignData';

const setLocalStoreageData = (data: RedesignData) => {
  localStorage.setItem(localStoreageKey, JSON.stringify(data));
};

const getLocalStoreageData = (): RedesignData => {
  const data = localStorage.getItem(localStoreageKey);

  if (!data) {
    return defaultData;
  }

  return JSON.parse(data);
};

export const useInvitation = () => {
  const [redesignSavedData, setRedesignSavedData] = useState<RedesignData>({
    ...getLocalStoreageData(),
  });
  const [redesignCurrentData, setRedesignCurrentData] = useState<RedesignData>({
    ...getLocalStoreageData(),
  });
  const [canSubmit, setCanSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { marketplace } = useParams();
  const { token, apiKey } = useAuthentication();
  const [isAddressAllowed, setIsAddressAllowed] = useState(true);
  const [isVatVerified, setIsVatVerified] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState<RawPlan>();
  const [applePayToken, setApplePayToken] = useState<null | string>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | null | PaymentData
  >(null);
  const { vin } = useContext(vehicleContext);

  const isRedesignMarketplaceAddressError = useMemo(() => {
    if (!redesignCurrentData.invoiceAddress?.country || !marketplace) {
      return false;
    }

    return (
      redesignCurrentData.invoiceAddress.country.toLowerCase() !== marketplace
    );
  }, [redesignCurrentData.invoiceAddress, marketplace]);

  const fetchPaymentMethods = useCallback(async () => {
    const paymentMethodsResponse = await fetch(
      `${process.env.REACT_APP_PORSCHE_PAYMENTS_API_URL}/mypaymentmethods/${marketplace}`,
      {
        method: 'POST',
        headers: {
          'apikey': apiKey,
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethodTypes: ['creditcard', 'directdebit', 'redirect'],
          curency: 'EUR',
          channel: 'Web',
          basket: [{ assortment: 'CHARGING', amount: 0 }],
        }),
      },
    );

    const paymentMethods = await paymentMethodsResponse.json();
    return paymentMethods;
  }, [token, apiKey, marketplace]);

  useEffect(() => {
    if (vin && marketplace) {
      if (
        redesignSavedData.vin !== vin ||
        redesignSavedData.marketplace !== marketplace
      ) {
        const mapToSave = {
          ...defaultData,
          vin,
          marketplace,
        };

        setLocalStoreageData(mapToSave);
        setRedesignCurrentData(mapToSave);
        return setRedesignSavedData(mapToSave);
      }
    }
  }, [vin, marketplace, redesignSavedData]);

  const findMatchingCreditCard =
    useCallback(async (): Promise<CreditCard | null> => {
      const paymentIdToMatch = redesignCurrentData.paymentRef?.id;
      const paymentMethodsMap = await fetchPaymentMethods();
      const paymentMethods = paymentMethodsMap?.paymentMethods;

      if (!paymentIdToMatch || !paymentMethods) {
        return null;
      }

      const creditCards = paymentMethods.find(
        (paymentMethod: PaymentMethod) => paymentMethod.method === 'creditcard',
      );

      if (!creditCards) {
        return null;
      }

      const matchingCreditCard = creditCards.types.reduce(
        (acc: CreditCard, type: CreditCradType) => {
          const creditCardDetails = type.details?.find(
            (detail) => detail.id === paymentIdToMatch,
          );

          if (creditCardDetails) {
            return { ...creditCardDetails, typeDisplayName: type.displayName };
          }

          return acc;
        },
        {},
      );

      if (matchingCreditCard.typeDisplayName) {
        return matchingCreditCard;
      }

      const redirectPaymentOptions = paymentMethods.find(
        (paymentMethod: PaymentMethod) => paymentMethod.method === 'redirect',
      );

      if (!redirectPaymentOptions) {
        return null;
      }

      const matchingRedirectPayment = redirectPaymentOptions.types.reduce(
        (acc: CreditCard, type: CreditCradType) => {
          const creditCardDetails = type.details?.find(
            (detail) => detail.id === paymentIdToMatch,
          );

          if (creditCardDetails) {
            return { ...creditCardDetails, typeDisplayName: type.displayName };
          }

          return acc;
        },
        {},
      );

      return matchingRedirectPayment;
    }, [redesignCurrentData, fetchPaymentMethods]);

  const isInitialApplePaySelected = useMemo(() => {
    if (
      typeof selectedPaymentMethod !== 'string' &&
      selectedPaymentMethod !== null
    ) {
      return (
        selectedPaymentMethod?.paymentMethodDetails?.paymentMethod.type ===
          'applepay' &&
        !selectedPaymentMethod?.paymentMethodDetails?.paymentMethod.id
      );
    }

    return false;
  }, [selectedPaymentMethod]);

  const isSubmitDisabled =
    !isVatVerified || !selectedPlan || !selectedPaymentMethod || !canSubmit;

  const planCurrency = useMemo(() => {
    if (!selectedPlan) {
      return 'EUR';
    }

    if (selectedPlan.region === Region.CA) {
      return 'CAD';
    }

    if (selectedPlan.region === Region.US) {
      return 'USD';
    }

    // Take currency code of base price if existing
    const base = selectedPlan?.options[marketplace || '']?.find((item) => {
      return item.feeType === FeeType.BASE;
    }) as BasePricing | undefined;

    if (base) {
      return base.pricingModel.price.grossAmount.currency;
    }

    // Just try our best at this point
    for (const item of selectedPlan?.options[marketplace || ''] || []) {
      const unknown = item as {
        pricingModel?: {
          priceTiers?: { price: Price }[];
        };
      };

      if (
        unknown.pricingModel?.priceTiers &&
        unknown.pricingModel.priceTiers[0]
      ) {
        return unknown.pricingModel.priceTiers[0].price.grossAmount.currency;
      }
    }

    return 'EUR';
  }, [marketplace, selectedPlan]);

  const planCurrencyIsRedesign = useMemo(() => {
    if (!redesignSavedData.plan) {
      return 'EUR';
    }

    if (redesignSavedData.plan.region === Region.CA) {
      return 'CAD';
    }

    if (redesignSavedData.plan.region === Region.US) {
      return 'USD';
    }

    // Take currency code of base price if existing
    const base = redesignSavedData.plan?.options[marketplace || '']?.find(
      (item) => {
        return item.feeType === FeeType.BASE;
      },
    ) as BasePricing | undefined;

    if (base) {
      return base.pricingModel.price.grossAmount.currency;
    }

    // Just try our best at this point
    for (const item of redesignSavedData.plan?.options[marketplace || ''] ||
      []) {
      const unknown = item as {
        pricingModel?: {
          priceTiers?: { price: Price }[];
        };
      };

      if (
        unknown.pricingModel?.priceTiers &&
        unknown.pricingModel.priceTiers[0]
      ) {
        return unknown.pricingModel.priceTiers[0].price.grossAmount.currency;
      }
    }

    return 'EUR';
  }, [marketplace, redesignSavedData.plan]);

  const canSubmitRedesign = useMemo(() => {
    return (
      redesignSavedData.vin &&
      redesignSavedData.plan &&
      (redesignSavedData.plan.region !== Region.EU ||
        redesignSavedData.deliveryAddress ||
        redesignSavedData.isDeliveryAddressSameAsInvoiceAddress) &&
      redesignSavedData.invoiceAddress &&
      redesignSavedData.paymentRef &&
      canSubmit
    );
  }, [redesignSavedData, canSubmit]);

  return {
    canSubmit,
    setCanSubmit,
    setSelectedPlan,
    setSelectedPaymentMethod,
    isSubmitDisabled,
    selectedPaymentMethod,
    isLoading,
    setIsLoading,
    isAddressAllowed,
    setIsAddressAllowed,
    vin,
    selectedPlan,
    isVatVerified,
    applePayToken,
    setApplePayToken,
    setIsVatVerified,
    isInitialApplePaySelected,
    planCurrency,
    setRedesignSavedData,
    redesignSavedData,
    redesignCurrentData,
    setRedesignCurrentData,
    findMatchingCreditCard,
    setLocalStoreageData,
    planCurrencyIsRedesign,
    canSubmitRedesign,
    isRedesignMarketplaceAddressError,
  };
};
