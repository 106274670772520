import {
  PButton,
  PHeading,
  PInlineNotification,
  PTag,
  PText,
} from '@porsche-design-system/components-react';
import { EuropeOnly } from 'components/context/plan/discriminators/region/EuropeOnly';
import { observer } from 'mobx-react-lite';
import { Address } from 'pages/activation/components/AddressPreview';
import { AddressWidget } from 'pages/activation/components/AddressWidget';
import { TAddressChangeEventPayload } from 'pages/activation/components/AddressWidget';
import { BusinessVerification } from 'pages/activation/components/BusinessVerification';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from './adressForm.messages';
import CardAddress from './CardAddress';
import style from './style.module.scss';
import { useAddress } from './useAddress';
import { messages as checkoutMessages } from '../../../activation/checkout.messages';

interface IProps {
  onVatVerification: (isVatVerified: boolean) => void;
  isRedesign?: boolean;
  currentAddress?: Address | null;
  onAddressChange?: (address: Address) => void;
  isMarketplaceError?: boolean;
}

const AddressForm: FC<IProps> = observer(
  ({ onVatVerification, isRedesign, onAddressChange, isMarketplaceError }) => {
    const {
      authentication,
      handleAddressChange,
      requestBusinessVerification,
      hasVerificatioErrored,
      fullAddress,
      handleBusinessChange,
      hasDoneVerification,
      isLoading,
      handleBusinessVerificationSubmit,
      newBusinessVerificationData,
      fetchAddressById,
    } = useAddress({ onVatVerification });

    const redesignHandleAddressChange = async (
      addressPayload: TAddressChangeEventPayload,
    ) => {
      await handleAddressChange(addressPayload);

      const newFullAddress = await fetchAddressById(addressPayload.toString());

      if (onAddressChange && newFullAddress) {
        onAddressChange(newFullAddress);
      }
    };

    const intl = useIntl();

    return (
      <>
        {isRedesign ? null : (
          <>
            <PHeading tag="h2" size="x-large">
              <FormattedMessage {...messages.title} />
            </PHeading>
            <PText size="small" weight="semi-bold">
              <FormattedMessage {...messages.subtitle} />
            </PText>
            <PText size="large" weight="bold" className={style.py3}>
              <FormattedMessage {...messages.billingAdress} />
            </PText>
          </>
        )}
        <AddressWidget
          onChange={
            isRedesign ? redesignHandleAddressChange : handleAddressChange
          }
          authorizationToken={authentication.token}
          name="addressId"
          defaultAddressId={fullAddress?.addressId}
        />

        {isRedesign && isMarketplaceError ? (
          <PInlineNotification
            className={style.inlineNotification}
            dismissButton={false}
            state="error"
            description={intl.formatMessage(
              checkoutMessages.addressNotAllowedError,
            )}
          />
        ) : null}

        <div className={style.pt2}>
          {fullAddress && requestBusinessVerification && (
            <>
              <PText size="large" weight="bold" className={style.pt3}>
                <FormattedMessage
                  {...checkoutMessages.bussinessVerificationSectionTitle}
                />
              </PText>
              <BusinessVerification
                legalName={
                  newBusinessVerificationData.legalName ??
                  fullAddress.companyName1 ??
                  ''
                }
                vat={newBusinessVerificationData.vat ?? fullAddress.vatId ?? ''}
                onChange={handleBusinessChange}
                hasError={hasVerificatioErrored}
              />
              <div className={style.pt2}>
                <PButton
                  type="button"
                  onClick={handleBusinessVerificationSubmit}
                  loading={isLoading}
                  disabled={
                    !(
                      newBusinessVerificationData.legalName ??
                      fullAddress.companyName1
                    ) || !(newBusinessVerificationData.vat ?? fullAddress.vatId)
                  }
                >
                  {intl.formatMessage(
                    checkoutMessages.billingAddressActionButtonLabel,
                  )}
                </PButton>
              </div>
            </>
          )}
          {hasDoneVerification && !hasVerificatioErrored && (
            <PTag className={style.pushed} color="notification-success-soft">
              Verified
            </PTag>
          )}
        </div>

        {isRedesign ? null : (
          <EuropeOnly>
            <PText size="large" weight="bold" className={style.py3}>
              <FormattedMessage
                {...messages.chargingCardDeliveryAddressLabel}
              />
            </PText>
            <CardAddress />
          </EuropeOnly>
        )}
      </>
    );
  },
);

export default AddressForm;
