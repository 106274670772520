import { PDivider, PText } from '@porsche-design-system/components-react';
import { featureFlagsContext } from 'components/context/featureFlags/FeatureFlagsContext';
import { EuropeOnly } from 'components/context/plan/discriminators/region/EuropeOnly';
import { PlanContext } from 'components/context/plan/PlanContext';
import { RawPlan } from 'components/context/plan/planContext.types';
import { PlanContextProvider } from 'components/context/plan/PlanContextProvider';
import { BasicLayout } from 'components/layout/basic/BasicLayout';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { useFetchAddress } from 'hooks/useFetchAddress';
import { Region } from 'hooks/usePlans';
import { useRegion } from 'hooks/useRegion';
import { triggerApplePayTransaction } from 'pages/activation/components/PaymentWidget/paymentRequests';
import { isVatCheckRequired } from 'pages/activation/useCheckout';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { getFormData } from 'utils/getFormData';

import { addNewSubscription } from './addNewSubscription';
import AddressError from './components/AddressError';
import AddressForm from './components/AddressForm';
import CardAddress from './components/AddressForm/CardAddress';
import { fetchBusinessVerification } from './components/AddressForm/utils';
import InvitationHeader from './components/InvitaionHeader';
import { InvitationBanner } from './components/InvitationBanner/InvitationBanner';
import { InvitationExpandableContent } from './components/InvitationExpandableContent/InvitationExpandableContent';
import InvitationOrderSummary from './components/InvitationOrderSummary';
import PaiementForm from './components/PaiementForm';
import PlanSelection from './components/PlanSelection';
import Stepper from './components/Stepper';
import SubmitSection from './components/SubmitSection';
import ToCCheckboxes from './components/ToCCheckboxes';
import { useGetConnectRedirectionUrl } from './getConnectRedirectionUrl';
import style from './invitation.module.scss';
import { messages } from './invitationPage.messages';
import type { RedesignData } from './useInvitation';
import { useInvitation } from './useInvitation';

type TInvitationForm = {
  chargingTariffId: string;
  addressId: string;
} & ({ cardDeliveryAddressSameAsBilling: 'on' } | { cardAddressId: string });

const InvitationProcess = () => {
  const redirectionUrl = useGetConnectRedirectionUrl();
  const featureFlagContext = useContext(featureFlagsContext);
  const region = useRegion();
  const { token, apiKey, ciamId } = useAuthentication();
  const { fetchAddressById } = useFetchAddress();
  const { locale, marketplace } = useParams();
  const isRedesignEnabled =
    featureFlagContext.featureFlags.invitationRedesign?.enabled;
  const intl = useIntl();
  const {
    setCanSubmit,
    canSubmit,
    isSubmitDisabled,
    setSelectedPaymentMethod,
    setSelectedPlan,
    selectedPaymentMethod,
    vin,
    setIsLoading,
    isLoading,
    selectedPlan,
    isAddressAllowed,
    setIsAddressAllowed,
    setIsVatVerified,
    applePayToken,
    isInitialApplePaySelected,
    setApplePayToken,
    planCurrency,
    setRedesignSavedData,
    redesignSavedData,
    redesignCurrentData,
    setRedesignCurrentData,
    findMatchingCreditCard,
    setLocalStoreageData,
    planCurrencyIsRedesign,
    canSubmitRedesign,
    isRedesignMarketplaceAddressError,
    isVatVerified,
  } = useInvitation();

  const updateSelectedPlans = useCallback(
    (planId: string, rawPlan: RawPlan) => setSelectedPlan(rawPlan),
    [setSelectedPlan],
  );

  const updateCurrentData = useCallback(
    <Key extends keyof RedesignData>(
      changeKey: Key,
      changeValue: RedesignData[Key],
    ) => {
      setRedesignCurrentData((prev) => {
        return { ...prev, [changeKey]: changeValue };
      });
    },
    [setRedesignCurrentData],
  );

  const updateCurrentDataPaymentMethod = useCallback(
    (paymentMethodId: string | null) => {
      if (!paymentMethodId) {
        return;
      }

      updateCurrentData('paymentRef', {
        id: paymentMethodId,
      });
      setSelectedPaymentMethod(paymentMethodId);
    },
    [updateCurrentData],
  );

  const updateSavedDataPaymentMethod = useCallback(async () => {
    const matchingCreditCard = await findMatchingCreditCard();

    if (!matchingCreditCard) {
      return;
    }

    const dataToSet = { ...redesignSavedData, paymentRef: matchingCreditCard };
    setRedesignSavedData(dataToSet);
    setRedesignCurrentData(dataToSet);
    setLocalStoreageData(dataToSet);
  }, [
    findMatchingCreditCard,
    setRedesignSavedData,
    redesignSavedData,
    setLocalStoreageData,
  ]);

  const paymentPreviewText = useMemo(() => {
    if (!redesignSavedData?.paymentRef) {
      return '';
    }

    const prefix = redesignSavedData?.paymentRef.typeDisplayName;
    const suffix = redesignSavedData?.paymentRef.displayName
      ? `****${redesignSavedData?.paymentRef.displayName?.slice(-4)}`
      : redesignSavedData?.paymentRef.shopperEmail;

    return `${prefix} - ${suffix}`;
  }, [redesignSavedData?.paymentRef]);

  const handleIsRedesignSubmit = async () => {
    const {
      paymentRef,
      invoiceAddress: address,
      isDeliveryAddressSameAsInvoiceAddress,
      deliveryAddress,
      plan,
    } = redesignSavedData;

    if (!paymentRef?.id) {
      return;
    }

    setIsLoading(true);

    if (!address || address?.country.toLowerCase() !== marketplace) {
      setIsLoading(false);
      setIsAddressAllowed(false);
      return;
    }

    setIsAddressAllowed(true);

    if (isVatCheckRequired(address)) {
      const isAddressVerified = await fetchBusinessVerification({
        legalName: address.companyName1 ?? '',
        vat: address.vatId ?? '',
        country: address.country ?? '',
        authorizationToken: token,
      });

      if (!isAddressVerified) {
        setIsLoading(false);
        setIsVatVerified(false);
        return;
      }
    }

    setIsVatVerified(true);

    let paymentRefToUse = paymentRef?.id || '';

    if (isInitialApplePaySelected) {
      const applePayMethodIdResponse = await triggerApplePayTransaction({
        apiKey,
        token,
        ciamId,
        marketplace,
        userAgent: navigator.userAgent,
        applePayToken: applePayToken || '',
        currency: planCurrencyIsRedesign,
      });
      paymentRefToUse = applePayMethodIdResponse?.paymentMethodId || '';
    }

    const orderIdToAppend = await addNewSubscription({
      vin,
      deliveryAddressId: isDeliveryAddressSameAsInvoiceAddress
        ? address.addressId
        : (deliveryAddress?.addressId as string),
      planVariant: plan?.variant as string,
      invoiceAddressId: address.addressId,
      paymentRef: paymentRefToUse,
      locale: locale!,
      token,
    });

    setIsLoading(false);

    let redirectionURL;

    if (orderIdToAppend && 'id' in orderIdToAppend) {
      redirectionURL = new URL(redirectionUrl.defaultRedirection);
      redirectionURL.searchParams.append('charging', orderIdToAppend.id);
    } else {
      redirectionURL = new URL(redirectionUrl.redirectionIfNoChargingOrder);
    }

    window.location.replace(redirectionURL.toString());
  };

  const content = [
    {
      id: 'tariff',
      title: intl.formatMessage(messages.chargingTarrifSelectTitle),
      content: (
        <div className={style.openContentContainer}>
          <PlanSelection
            onPlanChanged={(planId, plan) => updateCurrentData('plan', plan)}
            isRedesign={isRedesignEnabled}
            currentPlanId={redesignSavedData.plan?.id}
          />
        </div>
      ),
      closedContent:
        redesignSavedData.plan?.name && marketplace && locale ? (
          <div className={style.closedContentContainer}>
            <PText>{redesignSavedData.plan.name}</PText>
            <PText>
              {
                new PlanContext({
                  plan: redesignSavedData.plan,
                  marketplace,
                  locale,
                }).baseFee
              }
            </PText>
          </div>
        ) : null,
      hasSavedData: !!redesignSavedData.plan,
      actionButtonText: intl.formatMessage(
        messages.sectionDefaultActionButtonLabel,
      ),
      isActionButtonDisabled: !redesignCurrentData.plan,
      handleActionButtonClick: () => {
        setRedesignSavedData(redesignCurrentData);
        setLocalStoreageData(redesignCurrentData);
      },
    },
    {
      id: 'billingAdress',
      title: intl.formatMessage(messages.billingAdressSelectTitle),
      content: (
        <div className={style.openContentContainer}>
          <AddressForm
            onVatVerification={setIsVatVerified}
            isMarketplaceError={isRedesignMarketplaceAddressError}
            isRedesign={isRedesignEnabled}
            currentAddress={redesignSavedData.invoiceAddress}
            onAddressChange={(address) =>
              updateCurrentData('invoiceAddress', address)
            }
          />
        </div>
      ),
      closedContent: redesignSavedData.invoiceAddress ? (
        <div className={style.closedContentContainer}>
          <PText>{redesignSavedData.invoiceAddress.street1}</PText>
          <PText>
            {redesignSavedData.invoiceAddress.city}{' '}
            {redesignSavedData.invoiceAddress.city}
          </PText>
          <PText>{redesignSavedData.invoiceAddress.country}</PText>
        </div>
      ) : null,
      hasSavedData: !!redesignCurrentData.invoiceAddress,
      actionButtonText: intl.formatMessage(
        messages.sectionDefaultActionButtonLabel,
      ),
      isActionButtonDisabled:
        isRedesignMarketplaceAddressError ||
        !redesignCurrentData.invoiceAddress ||
        !isVatVerified,
      handleActionButtonClick: () => {
        setRedesignSavedData(redesignCurrentData);
        setLocalStoreageData(redesignCurrentData);
      },
    },
    {
      id: 'rfidCardAddress',
      hideCard: region !== Region.EU,
      title: intl.formatMessage(messages.rfidAdressSelectTitle),
      content: (
        <div className={style.openContentContainer}>
          <EuropeOnly>
            <CardAddress
              isRedesign={isRedesignEnabled}
              currentAddress={redesignSavedData.deliveryAddress}
              isChecked={
                redesignCurrentData.isDeliveryAddressSameAsInvoiceAddress
              }
              toggleIsChecked={() => {
                updateCurrentData(
                  'isDeliveryAddressSameAsInvoiceAddress',
                  !redesignCurrentData.isDeliveryAddressSameAsInvoiceAddress,
                );
              }}
              onAddressChange={(address) =>
                updateCurrentData('deliveryAddress', address)
              }
            />
          </EuropeOnly>
        </div>
      ),
      closedContent: redesignSavedData.deliveryAddress ? (
        <div className={style.closedContentContainer}>
          <PText>{redesignSavedData.deliveryAddress.street1}</PText>
          <PText>
            {redesignSavedData.deliveryAddress.city}{' '}
            {redesignSavedData.deliveryAddress.city}
          </PText>
          <PText>{redesignSavedData.deliveryAddress.country}</PText>
        </div>
      ) : null,
      hasSavedData:
        !!redesignSavedData.deliveryAddress ||
        redesignCurrentData.isDeliveryAddressSameAsInvoiceAddress,
      actionButtonText: intl.formatMessage(
        messages.sectionDefaultActionButtonLabel,
      ),
      handleActionButtonClick: () => {
        setRedesignSavedData(redesignCurrentData);
        setLocalStoreageData(redesignCurrentData);
      },
    },
    {
      id: 'payment',
      title: intl.formatMessage(messages.selectPaymentMethodTitle),
      content: (
        <div className={style.openContentContainer}>
          <PaiementForm
            onPaymentMethodChanged={updateCurrentDataPaymentMethod}
            setApplePayToken={setApplePayToken}
            isRedesign={isRedesignEnabled}
          />
        </div>
      ),
      closedContent: redesignSavedData.paymentRef ? (
        <div className={style.closedContentContainer}>
          <PText>
            {paymentPreviewText ||
              intl.formatMessage(messages.paymentSectionUnknownPaymentMethod)}
          </PText>
        </div>
      ) : null,
      hasSavedData: !!redesignSavedData.paymentRef,
      actionButtonText: intl.formatMessage(
        messages.sectionDefaultActionButtonLabel,
      ),
      handleActionButtonClick: () => updateSavedDataPaymentMethod(),
    },
    {
      id: 'terms',
      alwaysOpen: true,
      title: intl.formatMessage(messages.tacTitle),
      content: (
        <div className={style.openContentContainer}>
          <ToCCheckboxes handleCanSubmitChange={setCanSubmit} />
        </div>
      ),
      closedContent: selectedPlan ? (
        <div className={style.closedContentContainer} />
      ) : null,
      hasSavedData: true,
      actionButtonText: intl.formatMessage(
        messages.activateServiceActionButtonLabel,
      ),
      isActionButtonDisabled: !canSubmitRedesign,
      handleActionButtonClick: () => handleIsRedesignSubmit(),
    },
  ];

  type ContentId = (typeof content)[number]['id'];

  type SectionOpenMap = Record<ContentId, boolean>;

  const defaultSectionOpenMap = content.reduce(
    (acc, curr) => (curr.alwaysOpen ? acc : { ...acc, [curr.id]: false }),
    {},
  );

  const [isSectionOpenMap, setIsSectionOpenMap] = useState<SectionOpenMap>(
    defaultSectionOpenMap,
  );

  const handleToggleIsOpen = (id: ContentId) => {
    return () => {
      setIsSectionOpenMap((prev) => {
        const allClosedMap = { ...defaultSectionOpenMap };
        return { ...allClosedMap, [id]: !prev[id] };
      });
    };
  };

  const handleEditButtonClick = (id: ContentId) => {
    return () => {
      const shouldResetCurrentData = isSectionOpenMap[id];
      handleToggleIsOpen(id)();

      if (!shouldResetCurrentData) {
        return;
      }

      setRedesignCurrentData(redesignSavedData);
    };
  };

  const findNextSectionIdToOpen = useCallback(() => {
    const openableSections = content.filter(
      (section) => !section.alwaysOpen && !section.hideCard,
    );

    const nextSection = openableSections.find(
      (section) => !section.hasSavedData,
    );

    return nextSection?.id;
  }, [content, redesignSavedData]);

  const handleActionButtonClick = useCallback(
    async (callbackFunc: () => void, secId: string) => {
      await callbackFunc();

      const nextSectionIdToOpen = findNextSectionIdToOpen();

      handleToggleIsOpen(nextSectionIdToOpen || secId)();
    },
    [findNextSectionIdToOpen],
  );

  useEffect(() => {
    if (!isRedesignEnabled) {
      return;
    }

    const areAllSectionsClosed = Object.values(isSectionOpenMap).every(
      (isOpen) => !isOpen,
    );

    if (!areAllSectionsClosed) {
      return;
    }

    const nextSectionIdToOpen = findNextSectionIdToOpen();

    if (!nextSectionIdToOpen) {
      return;
    }

    handleToggleIsOpen(nextSectionIdToOpen)();
  }, [redesignSavedData, findNextSectionIdToOpen, isSectionOpenMap]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    const jsonForm = getFormData<TInvitationForm>(e);

    if (!selectedPaymentMethod) {
      return;
    }

    setIsLoading(true);

    const address = await fetchAddressById(jsonForm.addressId);

    if (!address || address?.country.toLowerCase() !== marketplace) {
      setIsLoading(false);
      setIsAddressAllowed(false);
      return;
    }
    setIsAddressAllowed(true);

    if (isVatCheckRequired(address)) {
      const isAddressVerified = await fetchBusinessVerification({
        legalName: address.companyName1 ?? '',
        vat: address.vatId ?? '',
        country: address.country ?? '',
        authorizationToken: token,
      });

      if (!isAddressVerified) {
        setIsLoading(false);
        setIsVatVerified(false);
        return;
      }
    }
    setIsVatVerified(true);

    let paymentRefToUse =
      typeof selectedPaymentMethod === 'string' ? selectedPaymentMethod : '';

    if (isInitialApplePaySelected) {
      const applePayMethodIdResponse = await triggerApplePayTransaction({
        apiKey,
        token,
        ciamId,
        marketplace,
        userAgent: navigator.userAgent,
        applePayToken: applePayToken || '',
        currency: planCurrency,
      });
      paymentRefToUse = applePayMethodIdResponse?.paymentMethodId || '';
    }

    const orderIdToAppend = await addNewSubscription({
      vin,
      deliveryAddressId:
        'cardDeliveryAddressSameAsBilling' in jsonForm
          ? jsonForm.addressId
          : jsonForm.cardAddressId,
      planVariant: jsonForm.chargingTariffId,
      invoiceAddressId: jsonForm.addressId,
      paymentRef: paymentRefToUse,
      locale: locale!,
      token,
    });

    setIsLoading(false);

    let redirectionURL;

    if (orderIdToAppend && 'id' in orderIdToAppend) {
      redirectionURL = new URL(redirectionUrl.defaultRedirection);
      redirectionURL.searchParams.append('charging', orderIdToAppend.id);
    } else {
      redirectionURL = new URL(redirectionUrl.redirectionIfNoChargingOrder);
    }

    window.location.replace(redirectionURL.toString());
  };

  return isRedesignEnabled ? (
    <BasicLayout>
      <PlanContextProvider
        plan={redesignCurrentData.plan}
        marketplace={marketplace!}
        locale={locale!}
      >
        <div className={style.contentWrapper}>
          <div className={style.stepperContainer}>
            <Stepper />
          </div>

          <div className={style.mainContentWrapper}>
            <InvitationBanner />

            <div className={style.informationContainer}>
              <div className={style.information}>
                <form onSubmit={handleSubmit}>
                  {content
                    .filter((item) => !item.hideCard)
                    .map((item, index) => (
                      <InvitationExpandableContent
                        key={item.id}
                        isOpen={item.alwaysOpen || isSectionOpenMap[item.id]}
                        title={item.title}
                        step={index + 1}
                        closedContent={item.closedContent}
                        handleEditButtonClick={handleEditButtonClick(item.id)}
                        handleActionButtonClick={() =>
                          handleActionButtonClick(
                            item.handleActionButtonClick,
                            item.id,
                          )
                        }
                        actionButtonText={item.actionButtonText}
                        isActionButtonDisabled={item.isActionButtonDisabled}
                        shouldHideEditButton={!item.hasSavedData}
                        toggleOpenButtonLabel={intl.formatMessage(
                          messages.toggleOpenButtonLabel,
                        )}
                      >
                        {item.content}
                      </InvitationExpandableContent>
                    ))}
                </form>
              </div>

              <div className={style.stickyOverviewContainer}>
                <div className={style.stickyContentContainer}>
                  <InvitationOrderSummary
                    selectedPlan={
                      redesignCurrentData.plan
                        ? redesignCurrentData.plan
                        : undefined
                    }
                    isRedesign={isRedesignEnabled}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={style.mobileStickyContentContainer}>
            <InvitationOrderSummary
              selectedPlan={
                redesignCurrentData.plan ? redesignCurrentData.plan : undefined
              }
              isRedesign={isRedesignEnabled}
              isExpandable
            />
          </div>
        </div>
      </PlanContextProvider>
    </BasicLayout>
  ) : (
    <BasicLayout>
      <main className={style.root}>
        <section>
          <Stepper className={style.stepper} />
        </section>
        <section>
          <InvitationHeader />
        </section>
        <form onSubmit={handleSubmit}>
          <section>
            <PlanSelection onPlanChanged={updateSelectedPlans} />
          </section>
          {selectedPlan && (
            <PlanContextProvider
              plan={selectedPlan}
              marketplace={marketplace!}
              locale={locale!}
            >
              <section>
                <InvitationOrderSummary selectedPlan={selectedPlan} />
              </section>
              <PDivider color="contrast-high" className={style.pt5} />
              <section className={style.pt3}>
                <AddressForm onVatVerification={setIsVatVerified} />
              </section>
              <section>
                <PaiementForm
                  onPaymentMethodChanged={setSelectedPaymentMethod}
                  setApplePayToken={setApplePayToken}
                />
              </section>
              <section className={style.pt4}>
                <ToCCheckboxes handleCanSubmitChange={setCanSubmit} />
              </section>
              <section>
                <AddressError isVisible={!isAddressAllowed} />
                <SubmitSection
                  isLoading={isLoading}
                  isSubmitDisabled={
                    isSubmitDisabled ||
                    (isInitialApplePaySelected && !applePayToken)
                  }
                  canSubmit={canSubmit}
                />
              </section>
            </PlanContextProvider>
          )}
        </form>
      </main>
    </BasicLayout>
  );
};

export default InvitationProcess;
