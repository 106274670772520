import { PCarousel, PIcon } from '@porsche-design-system/components-react';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './invitationBanner.messages';
import style from './invitationBanner.module.scss';
import background from '../../../../media/invitationBannerImage.png';

export const InvitationBanner: FC = () => {
  return (
    <div className={style.contentContainer}>
      <div className={style.imageContainer} style={{ width: '50%' }}>
        <img
          src={background}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '4px 0 0 4px',
          }}
        />
      </div>

      <div className={style.imageTextContainer} style={{ width: '50%' }}>
        <div>
          <div className={style.title}>
            <FormattedMessage {...messages.title} />
          </div>
        </div>
        <div className={style.textWithIconContainer}>
          <PIcon name="charging-station" color="contrast-low" size="medium" />
          <div className={style.text}>
            <FormattedMessage {...messages.subTitle1} />
          </div>
        </div>
        <div className={style.textWithIconContainer}>
          <PIcon name="purchase" color="contrast-low" size="medium" />
          <div className={style.text}>
            <FormattedMessage {...messages.subTitle2} />
          </div>
        </div>
        <div className={style.textWithIconContainer}>
          <PIcon name="duration" color="contrast-low" size="medium" />
          <div className={style.text}>
            <FormattedMessage {...messages.subTitle3} />
          </div>
        </div>

        <div className={style.absoluteColorGradient}></div>
      </div>

      <div className={style.mobileViewContainer}>
        <div>
          <div className={style.title}>
            <FormattedMessage {...messages.title} />
          </div>
        </div>

        <PCarousel>
          <div className={style.textWithIconContainerMobile}>
            <PIcon name="charging-station" color="contrast-low" size="medium" />
            <div className={style.text}>
              <FormattedMessage {...messages.subTitle1} />
            </div>
          </div>
          <div className={style.textWithIconContainerMobile}>
            <PIcon name="purchase" color="contrast-low" size="medium" />
            <div className={style.text}>
              <FormattedMessage {...messages.subTitle2} />
            </div>
          </div>
          <div className={style.textWithIconContainerMobile}>
            <PIcon name="duration" color="contrast-low" size="medium" />
            <div className={style.text}>
              <FormattedMessage {...messages.subTitle3} />
            </div>
          </div>
        </PCarousel>
      </div>
    </div>
  );
};
